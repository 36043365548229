<template lang="pug">
.table.table--highlight
  .table__body
    table
      thead
        tr
          th
          th {{ $t('adventages.traditionalStockExchange') }}
          th
            .gradient {{ $t('adventages.portfolioStockExchange') }}

      tbody
        tr
          td {{ $t('adventages.freeFloat') }}
          td {{ $t('adventages.traditional.freeFloat') }}
          td {{ $t('adventages.portfolio.freeFloat') }}

        tr
          td {{ $t('adventages.capitalization') }}
          td
            Amount(
              :value="2500000"
              :locale="locale"
              currency="EUR"
              is-integer)
            | / {{ $t('adventages.traditional.capitalization') }}
          td {{ $t('adventages.portfolio.capitalization') }}

        tr
          td {{ $t('adventages.liquidityProvider') }}
          td {{ $t('adventages.traditional.liquidityProvider') }}
          td {{ $t('adventages.notRequired') }}

        tr
          td {{ $t('adventages.listingSponsor') }}
          td {{ $t('adventages.traditional.listingSponsor') }}
          td {{ $t('adventages.notRequired') }}

        tr
          td {{ $t('adventages.listingTime') }}
          td {{ $t('adventages.traditional.listingTime') }}
          td {{ $t('adventages.portfolio.listingTime') }}

        tr
          td {{ $t('adventages.securitiesAccount') }}
          td {{ $t('adventages.traditional.securitiesAccount') }}
          td {{ $t('adventages.portfolio.securitiesAccount') }}

        tr
          td {{ $t('adventages.targetInvestor') }}
          td {{ $t('adventages.traditional.targetInvestor') }}
          td {{ $t('adventages.portfolio.targetInvestor') }}

        tr
          td {{ $t('adventages.sindicationTools') }}
          td {{ $t('adventages.traditional.sindicationTools') }}
          td {{ $t('adventages.portfolio.sindicationTools') }}

        tr
          td {{ $t('adventages.distribuitionToInvestors') }}
          td {{ $t('adventages.traditional.distribuitionToInvestors') }}
          td {{ $t('adventages.portfolio.distribuitionToInvestors') }}

        tr
          td {{ $t('adventages.settlement') }}
          td {{ $t('adventages.traditional.settlement') }}
          td {{ $t('adventages.portfolio.settlement') }}

        tr
          td {{ $t('adventages.beneficialOwnershipReports') }}
          td {{ $t('adventages.traditional.beneficialOwnershipReports') }}
          td {{ $t('adventages.portfolio.beneficialOwnershipReports') }}

        tr.row--total
          td {{ $t('adventages.overallListingCost') }}
          td
            Amount(
              :value="issuanceFees.initialFee.total.traditional"
              :locale="locale"
              currency="EUR"
              is-integer)
          td
            .gradient
              Amount(
                :value="issuanceFees.initialFee.total.portfolio"
                :locale="locale"
                currency="EUR"
                is-integer)

        tr.row--total
          td {{ $t('adventages.overallAnnualCost') }}
          td
            Amount(
              :value="issuanceFees.maintenanceFee.total.traditional"
              :locale="locale"
              currency="EUR"
              is-integer)
          td
            .gradient
              Amount(
                :value="issuanceFees.maintenanceFee.total.portfolio"
                :locale="locale"
                currency="EUR"
                is-integer)

  SavingMoney.margin-top-triple(
    :saving-money="issuanceFees.savingMoney"
    :saving-percent="issuanceFees.savingPercent"
    :locale="locale")

  .layout--center
    NuxtLinkLocale.button(to="/portfolio-fees") {{ $t('adventages.estimateFees') }}
</template>

<script lang="ts" setup>
import type { IssuanceFees } from '../types'

const { locale } = useI18n()
const amount = 40_000_000
const issuanceFees = ref<IssuanceFees>()

// Get the fees
issuanceFees.value = useGetIssuanceFees('equity', 'new', amount)
</script>

<template lang="pug">
.infographic
  .infographic__wrapper
    .infographic__row
      .infographic__col
        | &nbsp;
      .infographic__col
        .infographic__item.infographic__item--black.arrow--bottom#issuer {{ $t('infographic.issuer') }}
      .infographic__col
        | &nbsp;
    .infographic__row
      .infographic__col
        | &nbsp;
      .infographic__col
        .infographic__item.arrow--bottom#listingSponsor {{ $t('infographic.listingSponsor') }}
      .infographic__col
        | &nbsp;
    .infographic__row
      .infographic__col
        | &nbsp;
      .infographic__col
        .infographic__item.arrow--bottom#register {{ $t('infographic.register') }}
      .infographic__col
        | &nbsp;
    .infographic__row
      .infographic__col
        | &nbsp;
      .infographic__col
        .infographic__item.arrow--bottom#payingAgent {{ $t('infographic.payingAgent') }}
      .infographic__col
        | &nbsp;
    .infographic__row
      .infographic__col
        .infographic__item.arrow--bottom.custodian {{ $t('infographic.custodian') }}
      .infographic__col
        .infographic__item.arrow--bottom#liquidityProvider {{ $t('infographic.liquidityProvider') }}
      .infographic__col
        .infographic__item.arrow--bottom.custodian {{ $t('infographic.custodian') }}
    .infographic__row
      .infographic__col
        .infographic__item.arrow--right.marketMember {{ $t('infographic.marketMember') }}
      .infographic__col
        .infographic__item.arrow--bottom.infographic__item--black#stockExchange {{ $t('infographic.stockExchange') }}
        .infographic__item.arrow--bottom.infographic__item--blue#portfolio {{ $t('infographic.portfolioStockExchange') }}
      .infographic__col
        .infographic__item.arrow--left.marketMember {{ $t('infographic.marketMember') }}
    .infographic__row
      .infographic__col
        .infographic__item.arrow--top.broker {{ $t('infographic.broker') }}
      .infographic__col
        .infographic__item.arrow--bottom#memberClearing {{ $t('infographic.memberClearing') }}
      .infographic__col
        .infographic__item.arrow--top.broker {{ $t('infographic.broker') }}
    .infographic__row
      .infographic__col
        .infographic__item.arrow--top.orderManagement {{ $t('infographic.orderManagement') }}
      .infographic__col
        .infographic__item.arrow--bottom#clearingHouse {{ $t('infographic.clearingHouse') }}
      .infographic__col
        .infographic__item.arrow--top.orderManagement {{ $t('infographic.orderManagement') }}
    .infographic__row
      .infographic__col
        .infographic__item.infographic__item--black.arrow--top#buyer {{ $t('infographic.buyer') }}
      .infographic__col
        .infographic__item.infographic__item--black#centralSecurities {{ $t('infographic.centralSecurities') }}
      .infographic__col
        .infographic__item.infographic__item--black.arrow--top#seller {{ $t('infographic.seller') }}

</template>

<script setup>
const { $gsap } = useNuxtApp()

onMounted(() => {
  const tl = $gsap.timeline({
    scrollTrigger: {
      trigger: ".infographic",
      pin: true, // pin the trigger element while active
      start: "top top", // when the top of the trigger hits the top of the viewport
      end: "+=1000", // end after scrolling 500px beyond the start
      scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    },
  });

  // add animations and labels to the timeline
  tl.addLabel("start")
    .to("#listingSponsor", { scale: 0, autoAlpha: 0 })
    .to("#register", { scale: 0, autoAlpha: 0 })
    .to("#payingAgent", { scale: 0, autoAlpha: 0 })
    .to("#liquidityProvider", { scale: 0, autoAlpha: 0 })
    .to(".custodian", { scale: 0, autoAlpha: 0 })
    .to(".marketMember", { scale: 0, autoAlpha: 0 })
    .to(".broker", { scale: 0, autoAlpha: 0 })
    .to(".orderManagement", { scale: 0, autoAlpha: 0 })
    .to("#memberClearing", { scale: 0, autoAlpha: 0 })
    .to("#clearingHouse", { scale: 0, autoAlpha: 0 })
    .to("#issuer", { top: '17.5rem'})
    .to("#buyer, #seller", { bottom: '13.3rem'})
    .add(() => {
      document.getElementById('buyer')?.classList.toggle('arrow--top')
      document.getElementById('seller')?.classList.toggle('arrow--top')
    })
    .add(() => {
      document.getElementById('buyer')?.classList.toggle('arrow--right')
      document.getElementById('seller')?.classList.toggle('arrow--left')
    })
    .to("#centralSecurities", { bottom: '8.5rem'})
    .to("#stockExchange", { scale: 0, autoAlpha: 0, display: 'none'})
    .to("#portfolio", { scale: 1, autoAlpha: 1, display: 'flex'})
})
</script>

<style lang="scss" scoped>
.infographic {
  max-width: $content-width;
  padding: 0 half();
  margin: 0 auto;
  overflow: hidden;
}

.infographic__wrapper {
  display: table;
  margin: 0 auto;
}

.infographic__row {
  display: table-row;
}

.infographic__col {
  display: table-cell;
  vertical-align: middle;
  padding: half() half();
}

.infographic__item {
  position: relative;
  width: 12rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(color(gray8), color(gray7));
  border: 1px solid color(gray7);
  border-radius: $border-radius;
  font-size: font-size(small1);
  font-weight: font-weight(semibold);
  line-height: 1.1rem;
  padding: 0 half();
}

.infographic__item--black {
  background-image: linear-gradient(color(gray2), color(gray1));
  color: color(white);
  border-color: color(gray1);

  &.arrow--top {
    &::before {
      border-bottom: 0.5rem solid color(gray1);
    }
  }

  &.arrow--bottom {
    &::before {
      border-top: 0.5rem solid color(gray1);
    }
  }

  &.arrow--left {
    &::before {
      border-right: 0.5rem solid color(gray1);
    }
  }

  &.arrow--right {
    &::before {
      border-left: 0.5rem solid color(gray1);
    }
  }
}

.infographic__item--blue {
  background-image: linear-gradient(color(brand-light), color(brand));
  color: color(white);
  border-color: color(brand);

  &.arrow--top {
    &::before {
      border-bottom: 0.5rem solid color(brand);
    }
  }

  &.arrow--bottom {
    &::before {
      border-top: 0.5rem solid color(brand);
    }
  }

  &.arrow--left {
    &::before {
      border-right: 0.5rem solid color(brand);
    }
  }

  &.arrow--right {
    &::before {
      border-left: 0.5rem solid color(brand);
    }
  }
}

.arrow--bottom,
.arrow--top,
.arrow--left,
.arrow--right {
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }
}

.arrow--bottom {
  &::before {
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid color(gray7);
  }
}

.arrow--top {
  &::before {
    top: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid color(gray7);
  }
}

.arrow--left {
  &::before {
    top: 50%;
    left: -0.5rem;
    transform: translateY(-50%);
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-right: 0.5rem solid color(gray7);
  }
}

.arrow--right {
  &::before {
    top: 50%;
    right: -0.5rem;
    transform: translateY(-50%);
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-left: 0.5rem solid color(gray7);
  }
}

#portfolio {
  transform: scale(0);
  opacity: 0;
  display: none;
}

@include media(palm) {
  .infographic__col {
    padding: half() quarter();
  }

  .infographic__item {
    width: 8rem;
    font-size: 0.78rem;
    padding: 0 0;
  }
}
</style>

<template lang="pug">
.carousel
  h2.carousel__header(v-if="title") {{ title }}

  .carousel__container
    .carousel__images.carousel__images--primary
      .carousel__item(
        v-for="partner in partnersList"
        :key="partner.company"
        :style="{ backgroundImage: `url(${partner.img})` }"
        :alt="partner.company")

    .carousel__images.carousel__images--secondary
      .carousel__item(
        v-for="partner in partnersList"
        :key="partner.company"
        :style="{ backgroundImage: `url(${partner.img})` }"
        :alt="partner.company")
</template>

<script lang="ts" setup>
import partners from "../public/data/partners"

const partnersList = partners.lawFirms.concat(
  partners.mtfAdvisors,
  partners.investmentFirms,
  partners.listingAndCorporateServices,
  partners.companyAndAssetsValuations,
  partners.technologyProviders
)

defineProps<{
  title: string;
}>();
</script>

<style lang="scss" scoped>
$carousel-duration: 50s;
$carousel-item-width: 9rem;
$carousel-item-height: 9rem;
$carousel-gap: 4rem;

$carousel-items: 28;
$carousel-width: $carousel-items * ($carousel-item-width + $carousel-gap);

.carousel {
  padding: size(4) 0;
}

.carousel__header {
  text-align: center;
  margin: 0 auto;
  max-width: 50rem;
  margin-bottom: double();
}

.carousel__container {
  position: relative;
  width: 100%;
  height: $carousel-item-height;
  overflow-x: hidden;
  transform: translate3d(0, 0, 0);
  margin: 0 auto;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 1rem;
    height: 100%;
    pointer-events: none;
    z-index: 10;
  }

  &::before {
    left: 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }

  &::after {
    right: 0;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  }
}

.carousel__images {
  width: $carousel-width;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  transform: translate3d(0, 0, 0);
}

.carousel__images--primary {
  animation: scroll-horizontal-primary $carousel-duration linear infinite;
}

.carousel__images--secondary {
  animation: scroll-horizontal-secondary $carousel-duration linear infinite;
}

.carousel__item {
  width: $carousel-item-width;
  height: $carousel-item-height;
  overflow: hidden;
  margin-right: $carousel-gap;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  filter: grayscale(100%);

  &:hover {
    filter: none;
  }
}

@keyframes scroll-horizontal-primary {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-$carousel-width);
  }
}

@keyframes scroll-horizontal-secondary {
  0% {
    transform: translateX($carousel-width);
  }

  100% {
    transform: translateX(0);
  }
}

.carousel__container:hover {
  .carousel__images--primary {
    animation-play-state: paused;
  }

  .carousel__images--secondary {
    animation-play-state: paused;
  }

}

// .carousel__images--primary:hover,
// .carousel__images--primary:hover~.carousel__images--secondary {
//   -webkit-animation-play-state: paused;
//   -moz-animation-play-state: paused;
//   -o-animation-play-state: paused;
//   animation-play-state: paused;
// }

// .carousel__images--secondary:hover {
//   -webkit-animation-play-state: paused;
//   -moz-animation-play-state: paused;
//   -o-animation-play-state: paused;
//   animation-play-state: paused;
// }


// .carousel__container:hover>.carousel__images--primary:not(:hover) {
//   -webkit-animation-play-state: paused;
//   -moz-animation-play-state: paused;
//   -o-animation-play-state: paused;
//   animation-play-state: paused;
// }
</style>
